import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import {graphql} from "gatsby";
import Grid, {Col, Row} from "../components/grid/grid";
import styled from "styled-components";

const StyledGrid = styled(Grid)`
  margin-top: 30px;
  margin-bottom: 60px;

  @media (min-width: 960px) {
    padding: 0 100px;
  }
`

const Title = styled.h3`
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
`

const Description = styled.p`
  text-align: center;
  font-weight: 300;
`

export default function About({ data }: any) {
  const page = JSON.parse(data?.allContentfulPage?.nodes?.[0]?.description?.raw ?? '{}')
  const descriptions = page?.content ?? []
  return (
    <Layout>
      <SEO title="Home" />
        <StyledGrid>
          <Row>
            <Col>
              <Title>Who we are and what we do</Title>
              {descriptions.map((description: any, id: number) => <Description key={id}>{description.content?.[0].value}</Description>)}
            </Col>
          </Row>
        </StyledGrid>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPage(filter: {name: {eq: "about"}}) {
      nodes {
        description {
          raw
        }
      }
  }
}
`
